import clsx from "clsx";

export const Logo = (props: { className?: string; color?: boolean }) => {
  const { color = true } = props;
  return (
    <span className="inline-flex select-none items-center">
      <img src="/logo-e.svg" width="80px"/>
      {process.env.NEXT_PUBLIC_BETA === "1" ? (
        <span className="ml-2 inline-block rounded bg-rose-500 px-1 text-xs lowercase tracking-tight text-slate-50">
          beta
        </span>
      ) : null}
    </span>
  );
};
